<template>
  <div class="site-header ">
      <div class="container-fluid g-0">
        <div class="row w-100 g-0">
          <div class="col-lg-5 order-lg-1 order-2">
            <div class="intro-wrapper h-100">
              <router-link class="navbar-brand nav-logo d-lg-block d-none" to="/"><img src = "../../public/images/logo.svg" alt="Swift"/></router-link>
              <div class="typewriter animate__animated animate__fadeIn animate__delay-5ms">
                <h1 id="slider-txt">Your customer. Our business. We are BPO specialists with solutions truly aligned and customised to meet your unique business needs. </h1>
              </div>
            </div>
          </div>
          <div class="col-lg-7 order-lg-2 order-1">
            <div class="home-banner">
              
              <HeaderTag />
              <img src="../../public/images/home-banner.jpg" class="w-100"/>
            </div>
          </div>
        </div>
      </div>
  </div>
  <div class="switch-bg-lgrey pt-5" id="process">
    <div class="container">
      <div class="row">
        <div class="col-12 pb-lg- pb-md-0 pb-5"><h2>Your Customer <span class="switch-orange">.</span> Our Business</h2></div>
      </div>
    </div>
    <div class="container">
      <div class="row justify-content-between p-lg-5 p-md-5 g-0 animate__animated animate__fadeInUp">
        <div class="col-lg-5">
          <div class="about-section h-100">
            <div class="switch-bg-white h-100 px-3 py-4">
              <h2>What we do</h2>
              <p class="switch-black mt-3"><strong>We are BPO specialists who work with you to build a profitable and quality customer experience across your business. </strong></p>
            </div>
          </div>
        </div>
        <div class="col-lg-5 mt-lg-0 mt-5">
          <div class="about-section h-100">
            <div class="switch-bg-white h-100 px-3 py-4">
              <h2>How we do it </h2>
              <p class="switch-black mt-3"><strong>We combine our 32 years of experience with innovative thinking to generate groundbreaking solutions and successful outcomes. </strong></p>
              <p class="switch-black mt-3"><strong>This is done by analysing your individual business needs, designing solutions, defining outcomes, managing delivery, and continuously working to help your business stay ahead. </strong></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container py-5 mt-3 pb-0">
    <div class="row mb-3">
      <div class="col-12">
        <h1>How we operate </h1>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-9 col-md-9">
        <p class="text-center switch-black"><strong>To give your business a competitive advantage and drive customer experiences that strike a chord with your audience, we relentlessly focus on the following: </strong></p>
      </div>
    </div>
    <div class="row mb-3 mt-5">
      <div class="col-12">
        <div class="offerings-section">
          <div class="row mb-3 mt-5">
                <div class="col-lg-4 mb-lg-0 mb-5">
                    <div class="offerings-card h-100">
                        <div class="offerings-solutions offering-front h-100">
                            <div class="solutions-icon">
                                <div class="solutions-inner-icon">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" fill="currentColor" class="bi bi-people" viewBox="0 0 16 16">
                                  <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8Zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022ZM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816ZM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4Z"/>
                                </svg>
                                </div>
                            </div>
                            <div class="solutions-card">
                                <h3 class="text-uppercase switch-orange mb-4"><strong>PEOPLE</strong></h3>
                                <p class="switch-white text-center">There’s no you without your clients. And without you, and your business, there’s no us. So, we make sure to put the customer experience front and centre of everything we do.</p>
                            </div>
                        </div>
                        <div class="offerings-solutions switch-bg-orange offering-back h-100">
                            <div class="solutions-icon">
                                <div class="solutions-inner-icon">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" fill="currentColor" class="bi bi-people" viewBox="0 0 16 16">
                                  <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8Zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022ZM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816ZM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4Z"/>
                                </svg>
                                </div>
                            </div>
                            <div class="solutions-card">
                                <h3 class="text-uppercase mb-4"><strong>PEOPLE</strong></h3>
                                <p class="switch-white text-center">There’s no you without your clients. And without you, and your business, there’s no us. So, we make sure to put the customer experience front and centre of everything we do.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 mb-lg-0 mb-5">
                    <div class="offerings-card h-100">
                        <div class="offerings-solutions offering-front h-100">
                            <div class="solutions-icon">
                                <div class="solutions-inner-icon">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" fill="currentColor" class="bi bi-cpu" viewBox="0 0 16 16">
                                  <path d="M5 0a.5.5 0 0 1 .5.5V2h1V.5a.5.5 0 0 1 1 0V2h1V.5a.5.5 0 0 1 1 0V2h1V.5a.5.5 0 0 1 1 0V2A2.5 2.5 0 0 1 14 4.5h1.5a.5.5 0 0 1 0 1H14v1h1.5a.5.5 0 0 1 0 1H14v1h1.5a.5.5 0 0 1 0 1H14v1h1.5a.5.5 0 0 1 0 1H14a2.5 2.5 0 0 1-2.5 2.5v1.5a.5.5 0 0 1-1 0V14h-1v1.5a.5.5 0 0 1-1 0V14h-1v1.5a.5.5 0 0 1-1 0V14h-1v1.5a.5.5 0 0 1-1 0V14A2.5 2.5 0 0 1 2 11.5H.5a.5.5 0 0 1 0-1H2v-1H.5a.5.5 0 0 1 0-1H2v-1H.5a.5.5 0 0 1 0-1H2v-1H.5a.5.5 0 0 1 0-1H2A2.5 2.5 0 0 1 4.5 2V.5A.5.5 0 0 1 5 0zm-.5 3A1.5 1.5 0 0 0 3 4.5v7A1.5 1.5 0 0 0 4.5 13h7a1.5 1.5 0 0 0 1.5-1.5v-7A1.5 1.5 0 0 0 11.5 3h-7zM5 6.5A1.5 1.5 0 0 1 6.5 5h3A1.5 1.5 0 0 1 11 6.5v3A1.5 1.5 0 0 1 9.5 11h-3A1.5 1.5 0 0 1 5 9.5v-3zM6.5 6a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3z"/>
                                </svg>
                                </div>
                            </div>
                            <div class="solutions-card">
                                <h3 class="text-uppercase switch-orange mb-4"><strong>TECHNOLOGY</strong></h3>
                                <p class="switch-white text-center">Harnessing the power of cutting-edge technologies to change your tune and digitise your business is what we do best. We are here to help your business navigate digital transformation through the implementation of next-generation technology, such as Zendesk, Hey Jude App integration, Cloud telephony, predictive diallers, campaign management software and more.  </p>
                            </div>
                        </div>
                        <div class="offerings-solutions switch-bg-orange offering-back h-100">
                            <div class="solutions-icon">
                                <div class="solutions-inner-icon">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" fill="currentColor" class="bi bi-cpu" viewBox="0 0 16 16">
                                  <path d="M5 0a.5.5 0 0 1 .5.5V2h1V.5a.5.5 0 0 1 1 0V2h1V.5a.5.5 0 0 1 1 0V2h1V.5a.5.5 0 0 1 1 0V2A2.5 2.5 0 0 1 14 4.5h1.5a.5.5 0 0 1 0 1H14v1h1.5a.5.5 0 0 1 0 1H14v1h1.5a.5.5 0 0 1 0 1H14v1h1.5a.5.5 0 0 1 0 1H14a2.5 2.5 0 0 1-2.5 2.5v1.5a.5.5 0 0 1-1 0V14h-1v1.5a.5.5 0 0 1-1 0V14h-1v1.5a.5.5 0 0 1-1 0V14h-1v1.5a.5.5 0 0 1-1 0V14A2.5 2.5 0 0 1 2 11.5H.5a.5.5 0 0 1 0-1H2v-1H.5a.5.5 0 0 1 0-1H2v-1H.5a.5.5 0 0 1 0-1H2v-1H.5a.5.5 0 0 1 0-1H2A2.5 2.5 0 0 1 4.5 2V.5A.5.5 0 0 1 5 0zm-.5 3A1.5 1.5 0 0 0 3 4.5v7A1.5 1.5 0 0 0 4.5 13h7a1.5 1.5 0 0 0 1.5-1.5v-7A1.5 1.5 0 0 0 11.5 3h-7zM5 6.5A1.5 1.5 0 0 1 6.5 5h3A1.5 1.5 0 0 1 11 6.5v3A1.5 1.5 0 0 1 9.5 11h-3A1.5 1.5 0 0 1 5 9.5v-3zM6.5 6a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3z"/>
                                </svg>
                                </div>
                            </div>
                            <div class="solutions-card">
                                <h3 class="text-uppercase mb-4"><strong>TECHNOLOGY</strong></h3>
                                <p class="switch-white text-center">Harnessing the power of cutting-edge technologies to change your tune and digitise your business is what we do best. We are here to help your business navigate digital transformation through the implementation of next-generation technology, such as Zendesk, Hey Jude App integration, Cloud telephony, predictive diallers, campaign management software and more.  </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="offerings-card h-100">
                        <div class="offerings-solutions offering-front h-100">
                            <div class="solutions-icon">
                                <div class="solutions-inner-icon">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" fill="currentColor" class="bi bi-speedometer2" viewBox="0 0 16 16">
                                  <path d="M8 4a.5.5 0 0 1 .5.5V6a.5.5 0 0 1-1 0V4.5A.5.5 0 0 1 8 4zM3.732 5.732a.5.5 0 0 1 .707 0l.915.914a.5.5 0 1 1-.708.708l-.914-.915a.5.5 0 0 1 0-.707zM2 10a.5.5 0 0 1 .5-.5h1.586a.5.5 0 0 1 0 1H2.5A.5.5 0 0 1 2 10zm9.5 0a.5.5 0 0 1 .5-.5h1.5a.5.5 0 0 1 0 1H12a.5.5 0 0 1-.5-.5zm.754-4.246a.389.389 0 0 0-.527-.02L7.547 9.31a.91.91 0 1 0 1.302 1.258l3.434-4.297a.389.389 0 0 0-.029-.518z"/>
                                  <path fill-rule="evenodd" d="M0 10a8 8 0 1 1 15.547 2.661c-.442 1.253-1.845 1.602-2.932 1.25C11.309 13.488 9.475 13 8 13c-1.474 0-3.31.488-4.615.911-1.087.352-2.49.003-2.932-1.25A7.988 7.988 0 0 1 0 10zm8-7a7 7 0 0 0-6.603 9.329c.203.575.923.876 1.68.63C4.397 12.533 6.358 12 8 12s3.604.532 4.923.96c.757.245 1.477-.056 1.68-.631A7 7 0 0 0 8 3z"/>
                                </svg>
                                </div>
                            </div>
                            <div class="solutions-card">
                                <h3 class="text-uppercase switch-orange mb-4"><strong>AGILITY</strong></h3>
                                <p class="switch-white text-center">We adapt to businesses unique needs and demands with the suppleness of an expert contortionist. By remaining agile and adapting to your unique business needs and demands, we give you the flexibility to thrive and simply go with the flow. </p>
                            </div>
                        </div>
                        <div class="offerings-solutions switch-bg-orange offering-back h-100">
                            <div class="solutions-icon">
                                <div class="solutions-inner-icon">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" fill="currentColor" class="bi bi-speedometer2" viewBox="0 0 16 16">
                                  <path d="M8 4a.5.5 0 0 1 .5.5V6a.5.5 0 0 1-1 0V4.5A.5.5 0 0 1 8 4zM3.732 5.732a.5.5 0 0 1 .707 0l.915.914a.5.5 0 1 1-.708.708l-.914-.915a.5.5 0 0 1 0-.707zM2 10a.5.5 0 0 1 .5-.5h1.586a.5.5 0 0 1 0 1H2.5A.5.5 0 0 1 2 10zm9.5 0a.5.5 0 0 1 .5-.5h1.5a.5.5 0 0 1 0 1H12a.5.5 0 0 1-.5-.5zm.754-4.246a.389.389 0 0 0-.527-.02L7.547 9.31a.91.91 0 1 0 1.302 1.258l3.434-4.297a.389.389 0 0 0-.029-.518z"/>
                                  <path fill-rule="evenodd" d="M0 10a8 8 0 1 1 15.547 2.661c-.442 1.253-1.845 1.602-2.932 1.25C11.309 13.488 9.475 13 8 13c-1.474 0-3.31.488-4.615.911-1.087.352-2.49.003-2.932-1.25A7.988 7.988 0 0 1 0 10zm8-7a7 7 0 0 0-6.603 9.329c.203.575.923.876 1.68.63C4.397 12.533 6.358 12 8 12s3.604.532 4.923.96c.757.245 1.477-.056 1.68-.631A7 7 0 0 0 8 3z"/>
                                </svg>
                                </div>
                            </div>
                            <div class="solutions-card">
                                <h3 class="text-uppercase mb-4"><strong>AGILITY</strong></h3>
                                <p class="switch-white text-center">We adapt to businesses unique needs and demands with the suppleness of an expert contortionist. By remaining agile and adapting to your unique business needs and demands, we give you the flexibility to thrive and simply go with the flow. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
    <div class="row m-lg-5 m-md-5 py-5">
      <div class="col-lg-4">
        <div class="row my-lg-5 my-3">
          <div class="col-4 pe-0"><span class="highlight-value" id="count1"></span></div>
          <div class="col-6 align-self-center">
            <span class="highlight-title">Years</span>
            <span class="highlight-description">of experience</span>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="row my-lg-5 my-3">
          <div class="col-4 pe-0"><span class="highlight-value" id="count2"></span></div>
          <div class="col-6 align-self-center">
            <span class="highlight-title">Unique</span>
            <span class="highlight-description">locations and markets of operation</span>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="row my-lg-5 my-3">
          <div class="col-4 pe-0"><span class="highlight-value" id="count3"> </span></div>
          <div class="col-6 align-self-center">
            <span class="highlight-title ps-4">Languages</span>
            <!-- <span class="highlight-description mt-2">Maecenas luctus nisi in sem fermen blandit. In nec elit sollicitudin.</span> -->
          </div>
        </div>
      </div>
      <div class = "col-12">
        <router-link class="btn btn-primary mt-4 px-5" aria-current="page" to="contact" >Talk to us  
            <span class="inv-submit text-center"> Talk to us </span>
        </router-link>
      </div>
      <cookie-consent class="cookie-block"/>
    </div>
  </div>
  
</template>

<script>
import HeaderTag from './Header.vue'
import CookieConsent from 'vue-cookieconsent-component'

  export default {
    
    name: 'HomePage',
    components: {
      HeaderTag, CookieConsent
    }
  }
  
  window.addEventListener('load',function(){
  initSlider(
    'slider-txt', //id
    ['Your customer. Our business. We are BPO specialists with solutions truly aligned and customised to meet your unique business needs.'], 
    100, //duration
    1000 //delay
  );
});

//just copy and paste below
var createText = function(text,id,duration){
  document.getElementById('slider-txt').innerHTML = '';
  for(let i = 0; i < text.length; i++){
    setTimeout(()=>{
      let newText = text.substr(0,(i+1));
      document.getElementById(id).innerHTML = newText;
    },duration*i);
  }
}
var clearText = function(id,duration){
  let text = document.getElementById(id).innerHTML;
  for(let i = text.length; i > 0; i--){
    setTimeout(()=>{
      let newText = text.substr(0,text.length-i);
      document.getElementById(id).innerHTML = newText;
    },duration*i);
  }
}
var initSlider = function(id,texts,duration,delay){
  let durs = [];
  for(let i = 0; i < texts.length-1; i++){
    let beforeDur;
    if(i==0){
      beforeDur = 0;
    }
    else{
      beforeDur=durs[i-1];
    }
    durs.push((texts[i].length*duration*1) + (2.5*delay) + beforeDur);
  }
  
  let allTime = 0;
  for (let i = 0; i < texts.length; i++){
    allTime += (texts[i].length*duration*2.5) + (1.5*delay);
  } 
  let mainSlider = function(){
    for(let i = 0; i < texts.length; i++){
      setTimeout(()=>{
        createText(texts[i],id,duration);
        setTimeout(()=>{
        },texts[i].length*duration + delay);
      },i === 0 ? 0 : durs[i-1]);
    }
  }
  mainSlider();
  setInterval(()=>{
    mainSlider();
  },allTime);  
}

document.addEventListener("DOMContentLoaded", () => {
 function counter(id, start, end, duration) {
  let obj = document.getElementById(id),
   current = start,
   range = end - start,
   increment = end > start ? 1 : -1,
   step = Math.abs(Math.floor(duration / range)),
   timer = setInterval(() => {
    current += increment;
    obj.textContent = current;
    if (current == end) {
     clearInterval(timer);
    }
   }, step);
 }
 counter("count1", 0, 32, 3000);
 counter("count2", 0, 13, 2500);
 counter("count3", 0, 17, 3000);
});



</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >

  .about-section{
    background: url('../../public/images/bg-texture.jpg') no-repeat; 
    background-size: cover; 
    background-position: center;
    padding: 50px;
  }

  .offerings-section{
    background: url('../../public/images/footer-texture.png') no-repeat; 
    background-size: cover; 
    background-position: center;
    padding: 50px;
    background-attachment: fixed;
  }
  
  .solutions-icon{
    height: 79px; 
    width:79px; 
    border: #fff; 
    border-radius: 50%;
    background: #FFF;
    position:absolute;
    top:-40px;
    left: 50%;
    transform: translateX(-50%);
  }

  .solutions-inner-icon{
    height: 69px;
    width: 69px;
    background: #df762a;
    border: 2px solid #222;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding-top: 15px;
  }

  .offerings-solutions{
    background: #222222; 
    padding: 70px 25px;
    position: relative;
  }

  .highlight-value{
    font-size: 100px;
    color: #222;
    font-weight: 600;
  }
  .highlight-value sup{top:unset}

  .highlight-title{
    font-size: 20px;
    color: #222;
    font-weight: 600;
    display: block;
    /* padding-bottom: 10px; */
    text-align: left;
    position: relative;
  }

  #count3{position: relative;}
  #count3:before{
    position: absolute; 
    content: '+'; 
    font-size: 60px;
    bottom: 15px;
    right: -35px;
  }

  .highlight-description{
    font-size: 16px;
    color: #222;
    font-weight: 600;
    text-align: left;
    display: block;
  }



  .typewriter{
  /* height:50vh; */
  display:flex;
}

.btn{
    border-radius: 100px;
    background: #df762a;
    border: 1px solid #df762a;
    transition: 0.3s ease-in all;
    overflow: hidden;
    position: relative;
}

.btn:hover{
    background: #FFF;
    transition: 0.3s ease-in all;
    border-color: #df762a
}

.btn:hover .inv-submit{
    bottom: 7px;
    opacity: 1;
}

.inv-submit{
    position: absolute;
    display: block;
    bottom: -20px;
    transition: 0.3s ease-in all;
    opacity: 0;
    color: #df762a
}

.cookie-block{position: fixed; bottom: 0; left: 50%; transform: translateX(-50%); width: 100%; background: #FFF; z-index: 9; padding: 20px;box-shadow: 2px 2px 15px grey}
.cookie-consent-compliance{border-radius: 100px; background: #df762a; border: 1px solid #df762a; transition: 0.3s ease-in all; overflow: hidden; position: relative; color:#FFF; padding: 5px 15px; margin-top: 10px; transition: 0.3s ease all}
.cookie-consent-compliance:hover{border: 1px solid #df762a; transition: 0.3s ease-in all; color: #df762a;background: #fff;}
.cookie-consent-link{display:none}

  @media screen and (max-width: 1199px){
    .about-section{padding: 20px;}
  }

  @media screen and (max-width: 1024px){
    .about-section, .offerings-section{padding: 30px;}
  }

  @media screen and (max-width: 991px){}

  @media screen and (max-width: 768px){
    .about-section, .offerings-section{padding: 20px;}
  }

  @media screen and (max-width: 425px){
    .about-section, .offerings-section{padding: 10px;}
    .intro-wrapper{padding: 25px;}
  }
  
</style>
